import React from 'react';
import ExternalLink from 'components/atoms/external-link';

import { MediaSection } from './styles';

interface MediaImageLinkProps {
  url: string;
  imageSrc: string;
  alt: string;
  'data-testid': string;
}

const MediaImageLink = ({
  url,
  imageSrc,
  alt,
  ...rest
}: MediaImageLinkProps) => (
  <ExternalLink data-testid={rest['data-testid']} href={url}>
    <img src={imageSrc} alt={alt} />
  </ExternalLink>
);

export const MediaBar = () => (
  <MediaSection>
    <MediaImageLink
      data-testid="media_link_ny_post"
      url="https://nypost.com/2015/09/28/new-iphone-app-pushes-powerball-jackpot-near-record/"
      imageSrc="/press-news-link-nypost.svg"
      alt="New York Post"
    />
    <MediaImageLink
      data-testid="media_link_fortune"
      url="https://fortune.com/2018/10/30/term-sheet-thursday-october-30-2/"
      imageSrc="/press-news-link-fortune.svg"
      alt="Fortune"
    />
    <MediaImageLink
      data-testid="media_link_abc"
      url="https://abc7ny.com/lottery-lotto-new-york-state-jackpocket/1006498/"
      imageSrc="/press-news-link-abc.svg"
      alt="ABC"
    />
    <MediaImageLink
      data-testid="media_link_cbs"
      url="https://www.cbsnews.com/news/jackpocket-app-purchase-lottery-tickets-without-waiting-on-line/"
      imageSrc="/press-news-link-cbs.svg"
      alt="CBS"
    />
    <MediaImageLink
      data-testid="media_link_nbc"
      url="https://www.nbcnewyork.com/news/jackpocket-app_new-york/896320/"
      imageSrc="/press-news-link-nbc.svg"
      alt="NBC"
    />
    <MediaImageLink
      data-testid="media_link_cnbc"
      url="https://www.cnbc.com/2018/11/02/top-vc-deals-softbank-view-coinbase-monzo-athletic-gv-m12-accel.html"
      imageSrc="/press-news-link-cnbc.svg"
      alt="CNBC"
    />
    <MediaImageLink
      data-testid="media_link_axios"
      url="https://www.axios.com/newsletters/axios-pro-rata-66358987-b773-480d-9b6f-98c45b3b38a5.html"
      imageSrc="/press-news-link-axios.svg"
      alt="Axios"
    />
    <MediaImageLink
      data-testid="media_link_gma"
      url="https://www.youtube.com/watch?v=h7j7pR15PtE"
      imageSrc="/press-news-link-good-morning-america.svg"
      alt="Good Morning America"
    />
    <MediaImageLink
      data-testid="media_link_cheddar"
      url="https://cheddar.com/videos/jackpocket-buying-lottery-tickets-straight-from-your-phone"
      imageSrc="/press-news-link-cheddar.svg"
      alt="Cheddar"
    />
    <MediaImageLink
      data-testid="media_link_daily_news"
      url="https://www.nydailynews.com/new-york/queens/queens-man-wins-5g-daily-state-lottery-smartphone-app-article-1.2625686"
      imageSrc="/press-news-link-dailynews.svg"
      alt="Daily News"
    />
  </MediaSection>
);

export default MediaBar;
